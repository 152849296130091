.protfolio-body-css {
    width: 100%;
    margin-top: 7%;
    margin-bottom: 2%;
}

.card-image-size{
    width: fit-content;
    height: 200px;
}

.card-margin{
    width: 80%;
    margin-left: 10%;
}

@media screen and (max-width: 850px) {

    .card-margin{
        width: 90%;
        margin-left: 5%;
    }

    .protfolio-body-css {
        margin-top: 10%;
    }

}

@media screen and (max-width: 450px) {

    .protfolio-body-css {
        margin-top: 15%;
    }

    .card-margin{
        width: 90%;
        margin-left: 5%;
    }

}