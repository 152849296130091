.full-report-body {
    width: 100%;
    margin-top: 3%;
}

.startup-report-heading-div {
    width: 90%;
    margin-left: 5%;
}

.search-report-input {
    width: 60%;
    border-radius: 25px;
}

.report-menu-main-div {
    width: 90%;
    margin-left: 5%;
    display: flex;
    justify-content: space-between;

}

.report-menu-div {
    width: 25%;
    height: fit-content;
}

.report-content-div {
    width: 73%;
}

.report-menu-sub-div {
    width: 100%;
    border-radius: 4px;
}

.report-sub-menu-color {
    color: black !important;
    background-color: gray !important;
}

.branding-content p {
    margin-bottom: 0 !important;
}

.reportFontSize {
    font-size: 17px;
}

/* Payment component */

.containerSummary {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 20px;
}

.card-payment-div {
    width: 40%;
    background-color: white;
    border: 1px solid;
    padding: 20px;
    border-radius: 8px;
    margin-bottom: 20px;
    border-radius: 4 !important;
    transition: box-shadow 0.3s;
    height: fit-content;
}

.card-payment-div:hover {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
}

.card-payment-div:hover .price-color{
    color: #007BFF;
}

.card-payment-div h2 {
    font-size: 1.5rem;
    margin-bottom: 10px;
}

.card-payment-div p {
    margin-bottom: 5px;
}

.card-payment-div ul {
    padding-left: 20px;
}

.plan-featured {
    font-size: 12px;
    font-weight: 700;
    color: #fff;
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    border-bottom-right-radius: 1rem;
    border-top-left-radius: 0.8rem;
    min-width: 40%;
    padding-top: 3px;
    text-align: center;
    background-color: #007BFF;
}

@media (min-width: 1200px) {
    .card-width {
        width: 80%;
        margin-left: 10%;
    }

    .containerSummary {
        justify-content: space-around;
    }

    .card-payment-div {
        width: calc(48% - 20px);
        margin-bottom: 5%;
        margin-right: 10px;
        margin-left: 10px;
    }
}

@media (max-width: 768px) {
    .card-payment-div {
        width: calc(100% - 20px);
    }
}

@media screen and (max-width: 450px) {

    .containerSummary {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        padding: 20px;
    }

    .card-payment-div {
        width: calc(100% - 20px);
        /* Full width for smaller screens */
    }

    .report-menu-div {
        width: 33%;
    }

    .report-content-div {
        width: 65%;
    }
}