*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.startup-main-dashboard-body-div{
    width: 100%;
    margin-top: 5%;
}

.startup-main-heading-div-form{
    width: 100%;
    height: 150px;
    background-color: black;
    color: white;
    padding: 4% 0% 3% 7%;
}

.startup-dashboard-inside-shadow-div{
    width: 70%;
    margin-left: 15%;
    margin-top: -2%;
    background-color: white;
    border-radius: 6px;
    padding: 2% 3%;
    box-shadow: 0 3px 0px #e8e8e8;
}

.startup-dashboard-profile-form-div{
    width: 100%;
    margin-bottom: 2%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.startup-dashboard-profile-form-div>div:nth-child(1){
    width: 11%;
}

.startup-dashboard-profile-form-div>div:nth-child(1)>img{
    width: 80%;
    height: 100%;
    /* border-radius:; */
}

.startup-dashboard-profile-form-div>div:nth-child(2){
    width: 89%;
}

.startup-side-nav-component-content-div{
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.startup-side-nav-component-content-div>div:nth-child(1){
    width: 25%;
    height: fit-content;
}

.startup-side-nav-component-content-div>div:nth-child(2){
    width: 73%;
}

.basic-startup-form-div{
    width: 100%;
    color: black !important;
    /* background-color: aqua; */
}

.startup-navbar-color{
    color: black !important;
}

.nav-link.active {
    background-color: black !important;
    color: white !important;
}

.mobile-view-startup-dashboard{
    display: none;
}

.web-view-startup-dashboard{
    display: block;
}

.back-button-css-temp-mobile{
    display: none;
}

.back-button-css-temp{
    display: block;
}

@media screen and (max-width: 850px) {

    .startup-dashboard-profile-form-div>div:nth-child(1){
        width: 17%;
    }

    .startup-dashboard-profile-form-div>div:nth-child(2){
        width: 81%;
    }

}

@media screen and (max-width: 450px) {
    .startup-main-heading-div-form {
        padding: 8% 0% 3% 7%;
    }
    
    .startup-dashboard-profile-form-div>div:nth-child(1) {
        width: 50%;
        margin-left: 25%;
    }

    .startup-dashboard-inside-shadow-div{
        margin-top: -8%;
        width: 90%;
        margin-left: 5%;
    }

    .mobile-view-startup-dashboard{
        display: block;
    }

    .web-view-startup-dashboard{
        display: none;
    }

    .startup-side-nav-component-content-div>div:nth-child(1){
        width: 100%;
        height: fit-content;
    }
    
    .startup-side-nav-component-content-div>div:nth-child(2){
        width: 100%;
    }

    .back-button-css-temp-mobile{
        display: block;
    }

    .back-button-css-temp{
        display: none;
    }
}



