*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.startup-first-form-div{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.startup-first-form-div>input{
    width: 75%;
    margin-top: 2%;
    padding: 1%;
    border-radius: 4px;
}
