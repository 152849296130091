.summary-side-margin{
    margin-left: 20%;
    margin-right: 20%;
}

.full-report-button{
    border: none;
    background-color: black;
    color: white;
    margin-bottom: 2%;
    width: 35%;
    padding: 10px;
    border-radius: 10px;
    font-weight: 700;
}