
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.partner-account-main-div{
    width: 100%;
    margin-top: 5%;
}

.partner-account-form-div{
    box-shadow: 0 3px 12px #e8e8e8;
    width: 30%;
    border-radius: 10px;
    border-collapse: collapse;
    margin: auto;
    padding: 1rem;
    margin-bottom: 1rem;
}

.partner-label-div{
    display: flex;
    /* justify-content:     ; */
    width: 100%;
}

.partner-input-div{
    width: 100%;
    padding: 4px;
    margin-bottom: 1rem;
}

.partner-form-submit-div{
    width: 100%;
    display: flex;
    justify-content: center;
}

.partner-form-submit-button{
    width: 30%;
    border: none;
    border-radius: 5px;
    background-color: black;
    color: white;
    padding: 5px 0px;
}

.partner-select-role-css{
    margin-bottom: 4px;
}

@media screen and (max-width: 850px) {
    .partner-account-main-div {
        margin-top: 9%;
    }

    .partner-account-form-div{
        width: 80%;
    }   
}

@media screen and (max-width: 450px) {
    .partner-account-main-div {
        margin-top: 14%;
    }
    .partner-account-form-div{
        width: 90%;
    }
}