.background-color-startup-navbar {
    color: black !important;
}

.border-bottom-navbar-css {
    border-bottom: 1px solid #dee2e6;
}

.background-color-startup-navbar.active {
    background-color: transparent !important;
    color: black !important;
    border-bottom: 1px solid #c00;
}


/* general css */
.startup-general-body {
    width: 100%;
    margin: 0;
    padding: 0;
    border-bottom: 1px solid #dee2e6;
}

.startup-general-startup-name-div {
    width: 80%;
    display: flex;
    justify-content: space-between;
}

.startup-general-startup-name-div>div:nth-child(1) {
    width: 40%;
}

.startup-general-startup-name-div>div:nth-child(2) {
    width: 55%;
}

.startname-input-general {
    width: 90%;
    border: none;
    border: 1px solid #dee2e6;
    border-radius: 6px;
    box-shadow: 0 1px 3px #e8e8e8;
}

.startup-basic-general-save-button {
    width: 13%;
    border: none;
    float: right;
    border-radius: 6px;
    background-color: black;
    color: white;
}

/* Logo css */

.basic-logo-body-div {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.basic-logo-body-div>div:nth-child(1) {
    width: 12%;
}

.basic-logo-body-div>div:nth-child(1)>img {
    width: 90%;
    height: 100%;
}

.basic-logo-body-div>div:nth-child(2) {
    width: 69%;
}

.basic-logo-body-div>div:nth-child(3) {
    width: 17%;
}

.basic-logo-body-div>div:nth-child(3)>button {
    width: 80%;
    border: none;
    background-color: black;
    color: white;
    border-radius: 6px;
}

.horizontal-scrollable-web {
    overflow-x: auto;
    white-space: nowrap;
    flex-wrap: nowrap !important;
}

.horizontal-scrollable-web-visible {
    scrollbar-width: none;
}

/* Thin scrollbar for supported browsers */
.horizontal-scrollable-web {
    scrollbar-width: none;
}

@media screen and (max-width: 850px) {
    .horizontal-scrollable {
        overflow-x: auto;
        white-space: nowrap;
        flex-wrap: nowrap !important;
    }

    .basic-logo-body-div>div:nth-child(1)>img {
        width: 100%;
        height: 50%;
        padding-top: 10%;
        margin-top: 28%;
    }

    .basic-logo-body-div>div:nth-child(2) {
        width: 55%;
    }

    .basic-logo-body-div>div:nth-child(3)>button {
        width: 100%;
        margin-top: 17%;
    }

}



@media screen and (max-width: 450px) {
    .horizontal-scrollable {
        overflow-x: auto;
        white-space: nowrap;
        flex-wrap: nowrap !important;
    }

    .basic-logo-body-div>div:nth-child(1)>img {
        width: 100%;
        height: 50%;
        padding-top: 5%;
    }

    .basic-logo-body-div>div:nth-child(2) {
        width: 55%;
    }

    .basic-logo-body-div>div:nth-child(3)>button {
        width: 100%;
        margin-top: 17%;
    }

    .startup-general-startup-name-div{
        width: 100% !important;
    }

    .startup-general-startup-name-div>div:first-child{
        width: 43%;
    }
    /* .startup-general-startup-name-div>div:nth-child(2){
        width: ;
    } */
}

@media screen and (max-width: 380px) {

    .basic-logo-body-div>div:nth-child(1){
        width: 16%;
    }

}