* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

#navbar-header .navbar-heading-logo-color {
    font-weight: 700;
    line-height: 29.05px;
    text-align: center;
    width: 15%;
    font-size: 24px;
    position: relative;
    display: block;
    top: 0;
}

.navbar-on-right{
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.navbar-logo-width{
    width: 75%;
}

#navbar-header .navbar-heading-logo-color-1 {
    font-weight: 700;
    line-height: 29.05px;
    /* text-align: center; */
    /* width: 15%; */
    font-size: 24px;
    position: relative;
    display: block;
    top: 0;
}

.header-navbar-main-padding{
    padding: 1% 0% !important;
}

#navbar-header .navbar-menu-main-div{
    width: 65%;
}

#navbar-header .navbar-menu-div {
    width: 92%;
    margin-left: 4%;
    margin-right: 4%;
    border-radius: 10px;
}

#navbar-header .navbar-link-menu-div-last{
    width: 100%;
    border-radius: 10px;
    text-align: center;
}

#navbar-header .navbar-link-menu-div-last{
    text-align: center;
    font-size: 16px;
    line-height: 16px;
    font-weight: 700;
}

#navbar-header .navbar-link-menu-div {
    width: 20%;
    text-align: center;
    font-size: 16px;
    line-height: 16px;
    font-weight: 700;
}

.logo-cursor{
    cursor: pointer;
}

.nav-link-display-end{
    width: 30%;
    /* display: flex;
    justify-content: space-between;
    align-items: flex-end; */
}

.navbar-link-menu-div-investor-last{
    text-align: center;
    font-size: 20px !important;
    line-height: 16px;
    font-weight: 700;
    width: 33%;
}

.hpid{
    width: 40%;
}

.shpid{
    width: 70%;
}

.navbar-link-investor-menu-div{
    width: 33%;
}

.border-bottom-css-navbar{
    border-bottom: 1px solid #dbd6d6;
}

.startup-content-align{
    justify-content: end;
}



@media screen and (max-width: 850px) {

    

    #navbar-header .navbar-heading-logo-color {
        font-weight: 700;
        text-align: center;
        border-radius: 10px;
        width: 45%;
        line-height: 29.05px;
        font-size: 24px;
    }

    #navbar-header .navbar-menu-main-div{
        width: 100%;
    }

    #navbar-header .navbar-menu-div {
        width: 100%;
        margin-left: 0%;
        margin-right: 0%;
    }
    
    #navbar-header .navbar-link-menu-div {
        width: 100%;
        text-align: center;
        font-size: 16px;
        line-height: 16px;
        font-weight: 700;
    }

    .right-investor-header-div{
        width: 100%;
        display: flex;
        justify-self: center;
    }

    .i-mobile-view-size{
        width: 100%;
    }

    .hpid{
        width: 20%;
        margin-left: 37%;
    }

    .shpid{
        width: 20%;
        margin-left: 37%;
    }
}
