
.centered-container {
    display: flex;
    justify-content: center;
  }
  
  .radio-buttons {
    display: flex;
    flex-direction: row;
  }

  .plan-radio-button-gap-css{
    margin-left: 15px;
    margin-right: 15px;
  }

  .plan-margin-left-gap{
    margin-left: 5px;
  }

  .payment-button-center{
    display: flex !important;
    margin: auto !important;
    width: 40% !important;
    justify-content: center;
  }
  
  @media (max-width: 768px) {
    .radio-buttons {
      flex-direction: column;
    }

    .plan-radio-button-gap-css{
        margin-left: 0px;
        margin-right: 0px;
      }
  }