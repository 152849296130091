.report-form-div {
    width: 100%;
    margin: 0;
    padding: 0;
    border-bottom: 1px solid #dee2e6;
}

.report-input-div{
    border-bottom: 1px solid #dee2e6;
}

.report-textArea-div {
    width: 100%;
    border-radius: 6px;
}

.startup-report-generate-button{
    width: 20%;
    border: none;
    float: right;
    border-radius: 6px;
    background-color: black;
    color: white;
}

@media screen and (max-width: 850px) {

    .startup-report-generate-button{
        width: 36%;  
    }

}


@media screen and (max-width: 450px) {

    .startup-report-generate-button{
        width: 36%;  
    }

}