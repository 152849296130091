.my-innovation-body {
    width: 100%;
    padding-top: 5%;
}

.text-position {
    position: absolute;
    top: 63%;
    left: 41%;
}

.customfW {
    font-weight: 700;
}

.my-innovation-image-div {
    width: 90%;
    margin-left: 5%;
    height: fit-content;
}

.my-innovation-image-div>img {
    width: 100%;
    /* height: 500px; */
}

.my-innovation-searchbar-div {
    width: 50%;
    margin-left: 25%;
    display: flex;
    border: 1px solid black;
    border-radius: 50px;
    padding-left: 2%;
    margin-bottom: 2%;
}

.my-innovation-searchbar-div>input:nth-child(1) {
    width: 93%;
    border: none;
    outline: none;
}

.my-innovation-searchbar-div>img:nth-child(2) {
    width: 5%;
}

.my-innovation-suggestion-div {
    width: 50%;
    margin-left: 25%;
}

.my-innovation-suggestion-div-inside {
    width: 100%;
    display: flex;
}

.my-innovation-suggestion-div-inside>div {
    width: 33%;
    cursor: pointer;
}

.my-innovation-step-div {
    width: 90%;
    margin-left: 5%;
    background-color: black;
    color: white;
    padding-right: 10%;
    padding-left: 10%;
}

.innovation-three-step-div {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

.innovation-three-step-div>div:nth-child(1) {
    width: 33%;
}

.innovation-three-step-div>div:nth-child(2) {
    width: 33%;
}

.innovation-three-step-div>div:nth-child(3) {
    width: 33%;
}

.colorBlack {
    color: black;
}

.my-innovation-step-circle-div {
    width: 25px;
    height: 25px;
    border-radius: 25px;
    background-color: gray;
}

.my-innovation-solution-div {
    width: 90%;
    margin-left: 5%;
    border-radius: 4px;
    border: 1px solid black;
}

.common-font-weight {
    font-weight: 700;
}

.follow-question-div {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.follow-question-div>p:nth-child(1) {
    width: 95%;
}

.follow-question-div>p:nth-child(2) {
    width: 5%;
}

@media screen and (max-width: 850px) {

    .my-innovation-body {
        padding-top: 10%;
    }

    .my-innovation-searchbar-div {
        width: 65%;
        margin-left: 20%;
    }

    .text-position {
        top: 60%;
        left: 32%;
    }


}

@media screen and (max-width: 450px) {
    .my-innovation-body {
        padding-top: 15%;
    }

    .get-start-button-mobile-css {
        width: 30% !important;
    }

    .mobile-full-report-width-div {
        width: 55% !important;
    }

    .my-innovation-searchbar-div {
        width: 90%;
        margin-left: 5%;
    }

    .my-innovation-suggestion-div {
        width: 80%;
        margin-left: 10%;
    }

    .innovation-three-step-div>div:nth-child(1) {
        width: 100%;
    }

    .innovation-three-step-div>div:nth-child(2) {
        width: 100%;
    }

    .innovation-three-step-div>div:nth-child(3) {
        width: 100%;
    }

    .text-position {
        top: 60%;
        left: 17%;
    }
}