.interest-investor-main-div {
    width: 100%;
    margin-top: 7%;
    /* padding-top: 8% !important; */
    /* background-color: #f9f9f9; */
    padding-bottom: 3%;

}
.iiis-width{
    width: 100%;
}

@media screen and (max-width: 850px) {

    .interest-investor-main-div {
        width: 100%;
        margin-top: 5%;
        padding-top: 4% !important;
        /* background-color: #f9f9f9; */
        padding-bottom: 3%;
    
    }

}

@media screen and (max-width: 450px) {

    .interest-investor-main-div {
        width: 100%;
        margin-top: 7%;
        padding-top: 8% !important;
        /* background-color: #f9f9f9; */
        padding-bottom: 3%;
    
    }
}
