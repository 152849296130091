.overview-profile-body {
    width: 100%;
}

.overview-profile-startup-heading{
width: 100%;
padding: 2%;
margin-top: 5%;
border-bottom: 1px solid gray;
}

.overview-profile-startup-heading>{
    font-weight: 700;
}

.add-remain-button{
    border: none;
    font-weight: 700;
    width: fit-content;
    border-radius: 6px;
    cursor: pointer;
    box-shadow: 0 1px 3px #e8e8e8;
}

@media screen and (max-width: 850px) {

    .overview-profile-startup-heading{
        margin-top: 6%;
    }

}


@media screen and (max-width: 450px) {

    .overview-profile-startup-heading{
        margin-top: 10%;
    }

}

