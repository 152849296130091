.table-shadow {
    width: 80%;
    margin: auto;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
}

.report-coupon-table {
    width: 100%;
    border-collapse: collapse;
}

.report-table-heading,
.report-table-body {
    padding: 8px;
    text-align: left;
    border-bottom: 1px solid #ddd;
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
}

.report-table-heading {
    background-color: black;
    color: white;
}

.report-modal {
    display: block;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.4);
}

.report-modal-content {
    background-color: #fefefe;
    margin: 10% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 30%;
}

.report-modal-close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.report-modal-close:hover,
.report-modal-close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}

.report-modal form {
    display: flex;
    flex-direction: column;
}

.report-modal form input {
    margin-bottom: 10px;
    padding: 5px;
}

.add-coupon-submit-button {
    padding: 8px !important;
    margin: auto;
    background-color: black;
    color: white;
    border: none;
    width: 20%;
    cursor: pointer;
    border-radius: 6px;
}

.add-report-button-css {
    display: flex;
    margin: auto;
    background-color: black;
    border: none;
    padding: 8px;
    color: white;
    border-radius: 4px;
    margin-bottom: 10px;
}

.delete-coupon-button-css{
    background-color: #dc3545;
    border: none;
    padding: 8px;
    color: white;
    border-radius: 4px;
}

.delete-coupon-button-css:hover {
    background-color: #9d1b28;
}

@media screen and (max-width: 850px) {
    .report-modal-content{
        width: 80%;
        margin-top: 20%;
    }
}

@media screen and (max-width: 450px) {
    .report-modal-content{
        width: 80%;
        margin-top: 20%;
    }
}