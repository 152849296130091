.input-apply-wrapper {
    display: flex;
    align-items: center; /* Vertically center the items */
}

.discount-input { /* Grow to take available space */
    margin-right: 10px; /* Adjust spacing between input and button */
    width: 70%;
}

.apply-btn {
    /* Add any additional styling here */
    padding: 0px 10px; /* Adjust padding as needed */
    background-color: black;
    color: white;
    border: none;
    border: 1px solid black;
}