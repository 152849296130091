* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.common-p-tag-size {
    font-size: 18px;
}

.data-centric-div {
    width: 100%;
    /* margin-left: 5%; */
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.data-centric-div>div:nth-child(1) {
    width: 60%;
}

.data-centric-div>div:nth-child(2) {
    width: 38%;
    display: flex;
    justify-content: space-between;
}

.reaserch-emoji-1 {
    width: 48%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.reaserch-emoji-1>img {
    background: #D9D9D9;
    border-radius: 8px;
}

.reaserch-emoji-2 {
    width: 48%;
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-start;
}

.reaserch-emoji-2>img {
    background: #D9D9D9;
    border-radius: 8px;
}

.financial-modalling-image-div {
    width: 90%;
    margin-left: 5%;
    margin-bottom: 2%;
}

.financial-modalling-image-div>img {
    width: 100%;
}

.investor-hate-heading {
    color: #376AEE;
}

.inside-div-background {
    background: #D9D9D9 !important;
}

.financial-start-button-css {
    width: 60%;
    border: none;
    background-color: #376AEE;
    color: white;
    border-radius: 6px;
    font-weight: 700;
    margin: auto;
}

.email-subscribe-main-div {
    width: 90%;
    margin-left: 5%;
    margin-bottom: 2%;
    background: #D9D9D9 !important;
    border-radius: 8px;
    display: flex;
    padding-left: 7%;
    padding-right: 7%;
    justify-content: space-between;
    flex-wrap: wrap;
}

.email-subscribe-main-div>div:nth-child(1) {
    width: 50%;
}

.email-subscribe-main-div>div:nth-child(2) {
    width: 50%;
}

.email-input-common {
    width: 50%;
    border: none;
    border: 1px solid black;
    outline: none;
    border-radius: 6px;
    padding: 5px;
}

.subscribe-button-css {
    width: 50%;
    border: none;
    border-radius: 6px;
    border: 1px solid black;
    margin-left: -2%;
    background-color: black;
    color: white;
    font-weight: 700;
    padding: 5px;
}

@media screen and (max-width: 850px) {

    .email-subscribe-main-div {
        flex-wrap: wrap;
        margin-top: 2%;
        /* font-size: 12px; */
    }

    .email-subscribe-main-div>div:nth-child(1) {
        width: 100%;
    }

    .email-subscribe-main-div>div:nth-child(2) {
        width: 100%;
    }

    .subscribe-button-css {
        /* font-size: 10px !important; */
        position: absolute;
        margin-top: 22px;
        width: 35%;
        padding: 5px;
        margin-left: -10px;
    }

    .email-input-common {
        margin-top: 4% !important;
        padding-bottom: 2px;
        padding-left: 6px;
    }
}

@media screen and (max-width: 450px) {

    .subscribe-button-css{
        width: fit-content;
        font-size: 10px;
        margin-top: 13px;
        padding: 8px;
    }

.data-centric-div>div:nth-child(1) {
width: 100%;

}

.data-centric-div>div:nth-child(2) {
    width: 100%;
}

}