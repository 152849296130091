*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.investor-page-body{
    width: 100%;
    padding-top: 7%;
}

.only-the-best-first-div{
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    margin-top: 3%;
    margin-bottom: 3%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.only-the-best-first-div>div:nth-child(1){
    width:35%;
}

.only-the-best-first-div>div:nth-child(1)>img{
    width:100%;
}

.only-the-best-first-div>div:nth-child(2){
    width:60%;
}

.only-the-best-second-div{
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    margin-top: 3%;
    margin-bottom: 3%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.only-the-best-second-div>div:nth-child(1){
    width:60%;
}

.only-the-best-second-div>div:nth-child(2)>img{
    width:100%;
}

.only-the-best-second-div>div:nth-child(2){
    width:35%;
}

.investor-up-description-main-div{
    width: 100%;
    text-align: center;
    font-weight: 700;
    background-color: #f3f3f3;
    padding-top: 3%;
    padding-bottom: 3%;
}

.investor-up-description-main-div>h2:nth-child(2){
    padding: 0% 30%;
}

.investor-up-description-main-div>p:nth-child(3){
    text-align: center;
    width: 100%;
    padding: 0% 25%;
    font-size: 17px;
}

@media screen and (max-width: 850px) {

    .investor-page-body {
        padding-top: 9%;
    }
}

@media screen and (max-width: 450px) {

    .investor-page-body {
        padding-top: 15%;
    }
    .only-the-best-first-div>div:nth-child(1){
        width: 100%;
    }

    .only-the-best-first-div>div:nth-child(2){
        width: 100%;
        margin-top: 4%;
    }

    .only-the-best-second-div>div:nth-child(1){
        width: 100%;
    }

    .only-the-best-second-div>div:nth-child(2){
        width: 100%;
    }
}