* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.custom-link{
    text-decoration: none;
    color: black;
}

.admin-home-main-div {
    width: 100%;
    margin-top: 5%;
    overflow-x: auto;
}

table {
    box-shadow: 0 3px 12px #e8e8e8;
    width: 80%;
    border-radius: 10px;
    border-collapse: collapse;
    margin: auto;
}

th {
    padding: 1rem;
    background-color: black;
    color: white;
    text-align: left;
}

th:first-of-type {
    border-top-left-radius: 10px;
}

th:last-of-type {
    border-top-right-radius: 10px;
}

tr:hover {
    cursor: pointer;
    background-color: rgb(232, 232, 232, 0.638);
}

td {
    padding: 1rem;
}

.btn-container {
    margin: auto;
    width: 25rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    padding: 1rem;
}

.btn-container button {
    border: none;
    padding: 0.5rem 1rem;
    background-color: black;
    color: white;
    border-radius: 10px;
    cursor: pointer;
}

.btn-container button:disabled {
    cursor: not-allowed;
    background-color: rgb(229, 229, 229);
    color: rgba(0, 0, 0, 0.269);
}

.doc-image-width{
    width: 100%;
}

.delete-investor-btn-css{
    border: none;
    padding: 3px 10px;
    border-radius: 5px;
    background-color: red;
    color: white;
    cursor: pointer;
}

.delete-investor-btn-css:hover {
    background-color: rgb(179, 22, 22);
  }

@media screen and (max-width: 850px) {

    .admin-home-main-div {
        margin-top: 9%;
    }

}

@media screen and (max-width: 450px) {

    .admin-home-main-div {
        margin-top: 14%;
    }

}