*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.admin-investor-verification-div{
    width: 100%;
    margin-top: 5%;
    overflow-x: auto;
}

.verification-padding{
    padding: 1%;
}

.verify-submit-button{
    border: none;
    background-color: black;
    color: white;
    /* padding: 1%; */
    border-radius: 5px;
}

@media screen and (max-width: 850px) {

    .admin-investor-verification-div {
        margin-top: 9%;
    }

}

@media screen and (max-width: 450px) {

    .admin-investor-verification-div {
        margin-top: 14%;
    }

}
