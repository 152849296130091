* {
    margin: 0;
    padding: 0;
    font-family: 'Rubik';
}

.home-body {
    width: 100%;

}

.orange-color {
    color: #F28C28;
}

.banner-image-button-first-css {
    position: absolute;
    left: 30%;
    top: 60%;
    width: 17%;
    border: none;
    color: white;
    background-color: rgb(26, 19, 53);
    font-size: 21px;
    text-align: center;
    padding: 8px;
    border-radius: 10px;
    font-weight: 700;
}

.banner-image-button-first-css:hover {

    transform: scale(1.1);
    transition-duration: .3s;
    transition-property: transform;
}

.banner-image-button-second-css:hover {
    transform: scale(1.1);
    transition-duration: .3s;
    transition-property: transform;
}

.banner-image-button-second-css {
    position: absolute;
    left: 50%;
    top: 60%;
    width: 17%;
    border: none;
    color: white;
    background-color: rgb(26, 19, 53);
    font-size: 21px;
    text-align: center;
    padding: 8px;
    border-radius: 10px;
    font-weight: 700;
}

.home-banner-image-div {
    width: 100%;
    /* object-fit: contain; */
    padding-left: 5%;
    padding-right: 5%;
    position: relative;
}

.home-banner-image-div>img {
    width: 100%;
    /* height: 400px;
    object-fit: contain; */
}

.home-subheading-text {
    width: 90%;
    margin-left: 7%;
    margin-top: 2%;
    margin-bottom: 2%;
    font-weight: 700;
}

.home-banner-heading-text {
    width: 90%;
    margin-left: 7%;
}

.home-buzz-start-up-vision {
    width: 86%;
    margin-left: 7%;
}

.home-start-invest-button {
    cursor: pointer;
    width: 12%;
    margin-left: 7%;
    outline: none;
    border: none;
    border-radius: 10px;
    padding: 8px;
    margin-bottom: 1%;
    background-color: #031a1a;
    color: white;
    font-weight: 600;
}

.home-number-animation-css {
    width: 90%;
    padding-left: 10%;
    padding-top: 1%;
    padding-bottom: 1%;
    margin: 2% 5%;
    border-radius: 10px;
    display: flex;
    background-color: #f3f3f3;
}

.home-inside-number-animation-css {
    width: 25%;
}

.home-inside-number-animation-css>h1 {
    display: flex;
    font-weight: 800;
}

.home-live-deals-css {
    text-align: center;
}

.companys-cards-deals-div {
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 3%;
    margin-top: 1%;
}

.company-card-inside-deal-div {
    width: 22%;
    padding: 8px;
    border-radius: 10px;
    background-color: white;
}

.company-name-image-div {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-bottom: 4%;
}

.company-name-image-div>div:nth-child(1) {
    width: 20%;
}

.company-name-image-div>div:nth-child(1)>img {
    width: 100%;
}

.company-name-image-div>div:nth-child(2) {
    width: 75%;
}

.home-live-dashboard-company-description-font-size {
    font-size: 15px;
}

.live-dashboard-background-color {
    background-color: #f3f3f3;
    padding-top: 3%;
    padding-bottom: 2%;
    margin-bottom: 3%;
}

.valuation-deadline-div {
    width: 100%;
    border-top: 1px solid #e3e3e3;
    border-bottom: 1px solid #e3e3e3;
    display: flex;
    padding-top: 6px;
    padding-bottom: 6px;
    justify-content: space-between;
}

.valuation-deadline-div>div {
    width: 50%;
}

.valuation-deadline-div-1 {
    width: 100%;
    border-bottom: 1px solid #e3e3e3;
    display: flex;
    padding-top: 6px;
    padding-bottom: 6px;
    justify-content: space-between;
}

.valuation-deadline-div-1>div {
    width: 50%;
}

.valuation-deadline-div-2 {
    width: 100%;
    display: flex;
    padding-top: 6px;
    padding-bottom: 6px;
    justify-content: space-between;
}

.valuation-deadline-div-2>div {
    width: 50%;
}

.agritech-button {
    margin-top: 7%;
    width: 70%;
    /* border: none; */
    padding-top: 1%;
    padding-bottom: 4%;
    border: 1px solid #e3e3e3;
    border-radius: 25px;
    background-color: white;
}

.home-startup-align-div {
    text-align: center;
    font-weight: 700;
}

.home-startup-content-align-div {
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    text-align: center;
}

.home-news-main-div {
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 3%;
    margin-bottom: 3%;
}

.home-news-inside-main-div {
    width: 31%;
}

.home-news-inside-image-div {
    width: 100%;
    margin-bottom: 8%;
}

.font-weight-css {
    font-weight: 700;
}

.home-news-inside-image-div>img {
    width: 100%;
    /* height: 400px; */
    height: 300px;
    /* object-fit: contain; */
}

.home-news-inside-main-div>h5 {
    margin-bottom: 6%;
}

.home-article-color-css {
    color: #ee4437;
    cursor: pointer;
}

.home-browse-article-div {
    text-align: center;
}

.home-browse-article-div>button {
    border: none;
    background-color: black;
    color: white;
    margin-bottom: 2%;
    width: 15%;
    padding: 10px;
    border-radius: 10px;
    font-weight: 700;
}

.home-startup-detail-div {
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    display: flex;
    margin-top: 3%;
    margin-bottom: 7%;
    justify-content: space-between;
    flex-wrap: wrap;
}

.home-startup-detail-image {
    width: 49%;
}

.home-startup-detail-image>img {
    width: 100%;
}

.home-startup-detail-description-div {
    width: 49%;
}

.home-feature-startup-div {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 3%;
}

.home-feature-startup-image-div {
    width: 12%;
    padding: 3%;
    border: 1px solid #e3e3e3;
    border-radius: 10px;
    height: fit-content;
}

.home-feature-startup-image-div>img {
    width: 100%;
}

.home-feature-startup-description-div {
    width: 82%;
    font-size: 18px;
}

.home-startup-detail-description-div>button:nth-child(5) {
    width: 30%;
    border: none;
    background-color: black;
    color: white;
    padding: 7px;
    font-weight: 700;
    border-radius: 7px;
}

@media screen and (max-width: 850px) {
    .home-banner-image-div {
        width: 100%;
        padding-top: 10%;
    }

    .banner-image-button-first-css {
        left: 25%;
        top: 70%;
        width: 25%;
        font-size: 15px;
    }


    .banner-image-button-second-css {
        position: absolute;
        left: 55%;
        top: 70%;
        width: 20%;
        font-size: 15px;
    }

    .home-start-invest-button {
        cursor: pointer;
        width: 21%;
    }

    .company-card-inside-deal-div {
        width: 49%;
    }

    .home-browse-article-div>button {
        margin-bottom: 2%;
        width: fit-content;
    }

    
    .home-startup-detail-description-div>button:nth-child(5){
        width: 51%;
        margin-bottom: 1%;
    }

}

@media screen and (max-width: 450px) {

    .home-banner-image-div {
        width: 100%;
        padding-top: 17%;
    }

    .banner-image-button-first-css {
        position: absolute;
        left: 17%;
        top: 70%;
        width: 35%;
        border: none;
        color: white;
        background-color: rgb(26, 19, 53);
        font-size: 12px;
        text-align: center;
        padding: 6px;
        border-radius: 10px;
        font-weight: 700;
    }


    .banner-image-button-second-css {
        position: absolute;
        left: 55%;
        top: 70%;
        width: 30%;
        border: none;
        color: white;
        background-color: rgb(26, 19, 53);
        font-size: 12px;
        text-align: center;
        padding: 6px;
        border-radius: 10px;
        font-weight: 700;
    }

    .home-start-invest-button {
        cursor: pointer;
        width: 45%;
        margin-left: 7%;
        outline: none;
        border: none;
        border-radius: 10px;
        padding: 8px;
        margin-bottom: 1%;
        background-color: #031a1a;
        color: white;
        font-weight: 600;
    }

    .home-number-animation-css {
        width: 90%;
        margin: 2% 5%;
        border-radius: 10px;
        display: flex;
        flex-wrap: wrap;
        background-color: #f3f3f3;
    }

    .home-inside-number-animation-css {
        width: 50%;
    }

    .companys-cards-deals-div {
        width: 100%;
        padding-left: 5%;
        padding-right: 5%;
        display: block;
        margin-bottom: 3%;
        margin-top: 1%;
    }

    .company-card-inside-deal-div {
        width: 100%;
        padding: 8px;
        border-radius: 10px;
        margin-bottom: 2%;
    }

    .home-news-inside-main-div {
        width: 100%;
    }

    .home-browse-article-div>button {
        margin-bottom: 2%;
        width: fit-content;
    }

    .home-startup-detail-image {
        width: 100%;
    }


    .home-startup-detail-description-div {
        width: 100%;
        margin-top: 2%;
    }

    .home-startup-detail-description-div>button:nth-child(5){
        width: 48%;
        margin-bottom: 1%;
    }

}

@media screen and (max-width: 380px) {
    .banner-image-button-first-css {
            width: fit-content;
    }

    .home-browse-article-div>button{
        width: fit-content;
    }

    .home-startup-detail-description-div>button:nth-child(5){
        width: fit-content;
    }
}