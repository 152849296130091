.verify-otp-div {
    width: 50%;
    margin: auto;
}

.start-25 {
    position: absolute !important;
    left: 25% !important;
    top: 25% !important;
}

.verify-input-main-div{
    width: 100%;
}

.verify-label-div{
    width: 40%;
    display: flex;
    justify-content: end;
}

.verify-email-input-div{
    width: 57%;
}

.email-input{
    width: 60%!important;
}