.interest-single-startup-main-div {
    margin-top: 7%;
    padding-bottom: 3%;

}

.single-startup-detail-main-div-width{
    width: 100%;
}

.single-startup-detail-width {
    width: 50%;
}

@media screen and (max-width: 850px) {

    .interest-single-startup-main-div {
        width: 100%;
        margin-top: 5%;
        padding-top: 4% !important;
        padding-bottom: 3%;
    }

    .single-startup-detail-width {
        width: 90%;
    }

}

@media screen and (max-width: 450px) {

    .interest-single-startup-main-div {
        width: 100%;
        margin-top: 7%;
        padding-top: 8% !important;
        padding-bottom: 3%;
    }

    .single-startup-detail-width {
        width: 90%;
    }
}