
.startup-form-body{
    width: 100%;
    background-color: #f9f9f9;
    padding-top: 2%;
    padding-bottom: 2%;
    /* height: 100vh; */
}

.startup-inside-body{
    width: 50%;
    margin-left: 25%;
    padding: 2% 3%;
    box-shadow: 0 3px 12px #e8e8e8;
}

.startup-form-footer{
    margin-top: 2%;
    display: flex;
    justify-content: space-between;
}

.startup-form-footer>div:nth-child(2){
    width: 25%;
    display: flex;
    justify-content: space-around;
}

.startup-form-footer>div:nth-child(2)>button{
    width: 45%;
    border-radius: 4px;
    /* color: #F28C28; */
    background-color: white;
}

@media screen and (max-width: 850px) {
    .startup-inside-body{
        width: 90%;
        margin-left: 5%;
        padding: 2% 3%;
        box-shadow: 0 3px 12px #e8e8e8;
    }

    .startup-form-footer>div:nth-child(2) {
        width: 28%;
    }

}

@media screen and (max-width: 450px) {

    .startup-inside-body{
        width: 90%;
        margin-left: 5%;
        padding: 2% 3%;
        box-shadow: 0 3px 12px #e8e8e8;
    }

    .startup-form-footer>div:nth-child(2) {
        width: 28%;
    }

    .startup-form-footer>div:nth-child(2)>button {
        width: fit-content;
    }
}
